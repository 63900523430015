<template>
    <b-row>
  
      <!-- Small -->
       
  
      <!-- Large -->
      <b-col cols="12">
        <b-form-group>
          <v-select
            
            label="type"
            :options="categories"
            
          class="select-size-lg"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </template>
  
  <script>
  import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
//   import useUsersList from '../courseCategoryStoreModule'
  import axios from '@axios'
  import { integer } from 'vee-validate/dist/rules'
  export default {
    components: {
      BRow,
      BCol,
      BFormGroup,
      vSelect,
    },
    created () {
  
    },
    props :{
    //   initialCategories:[],
      initialId:integer,
      selected:{
        required:true
      }
    },
//    inject: ['categorydata'],
  
    methods:{
      updateSelections(a) {
      console.log(a)
    },
      fetchCategories( ) {  
      },
      // selected(e){
      //   this.$emit('handleSelection',{})
      // }
    },
    updated(){
        // this.selected={id:1,type:'Books'}
        // this.selectedId=1
        if(this.selected){
            this.selectedOption = {id:1,type:'Books'}
            
        }
    },
//     watch: {
//       categorydata:   {
//           handler:function (n, o)   {
//             console.log('new cat: ', n);
//                 if (n==null||n==undefined)
//                     {
//                       this.selectedId=null
//                     } else
//                     { 
//                       console.log('new client: ', n.selectedCategory);
//                     this.selectedId = n.selectedCategory.upperCategory;
//                     console.log("selecedId"+ this.selectedId );
//                      if ( this.selectedId!=null)
//                        {
  
          
//                              var   objIndex = this.categories.findIndex((obj => obj.id == this.selectedId))
//                          console.log("obj count="+this.categories.length);
//                          this.selected= this.categories[objIndex];
  
//                          }
//                     }
                    
          
  
//           },
//           deep: true
//       },
//     selected: function (newVal,coldVal) {
       
  
//        this.$emit("selectionChanged", newVal);
  
//     }
//   },
    data() {
      return {
        categories:[{id:1,type:'Books'},{id:2,type:'Courses'}],
        selectedOption: null,
      }
    }
     
  
  }
  </script>